module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Cinemeye Vision Studio","short_name":"Cinemeye Vision","description":"Cinematography & Media Production","start_url":"/","background_color":"#1f1f1f","theme_color":"#1f1f1f","display":"fullscreen","icon":"src/images/favicon.png","icons":[{"src":"/icons/android-icon-512x512.png","sizes":"512x512","type":"image/png"},{"src":"/icons/maskable-icon-196x196.png","type":"image/png","sizes":"196x196","purpose":"maskable any"},{"src":"/icons/android-icon-192x192.png","type":"image/png","sizes":"192x192"},{"src":"/icons/apple-icon-180x180.png","type":"image/png","sizes":"180x180"},{"src":"/icons/favicon-32x32.png","type":"image/png","sizes":"32x32"},{"src":"/icons/favicon-16x16.png","type":"image/png","sizes":"16x16"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"851a9c3779a74cca5fb6621c66f92bef"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
